import { useState, useEffect, useContext } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import Link from "next/link";
import AppContext from "context/app";
import Config from "components/config";
import { login } from "utils/auth";

export default function Login() {
  const [data, updateData] = useState({ identifier: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const router = useRouter();
  const appContext = useContext(AppContext);

  useEffect(() => {
    if (appContext.isAuthenticated) {
      router.push("/");
    }
  }, [appContext.isAuthenticated, router]);

  function onChange(event) {
    updateData({ ...data, [event.target.name]: event.target.value });
  }

  const submit = (event) => {
    setLoading(true);
    login(data.identifier, data.password)
      .then((res) => {
        setLoading(false);
        // set authed User in global context to update header/app state
        appContext.setUser(res.data.user);
        router.push("/");
      })
      .catch((err) => {
        console.error(err);
        setError(err.response.data);
        setLoading(false);
      });

    if (event && event.preventDefault) {
      event.preventDefault();
    }
  };

  return (
    <Config>
      <Head>
        <title>Sign In | Open Coaster</title>
        <meta name="description" content="Sign in to Open Coaster" />
      </Head>
      <main className="container m-6 mx-auto px-6 sm:px-0">
        <div className="mx-auto mb-8 w-full max-w-xs">
          <h1 className="mb-8 text-3xl text-gray-900">Sign In</h1>
          <form onSubmit={submit}>
            <div className="mb-4">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="identifier"
              >
                Username
              </label>
              <input
                className="flex w-full items-center rounded border border-gray-200 bg-white p-3 text-sm shadow-sm outline-none focus:outline-none"
                id="identifier"
                name="identifier"
                type="text"
                placeholder="Username"
                autoComplete="username"
                disabled={loading}
                onChange={(event) => onChange(event)}
                value={data.identifier}
              />
            </div>
            <div className="mb-6">
              <label
                className="mb-2 block text-sm font-bold text-gray-700"
                htmlFor="password"
              >
                Password
              </label>
              <input
                className="flex w-full items-center rounded border border-gray-200 bg-white p-3 text-sm shadow-sm outline-none focus:outline-none"
                id="password"
                name="password"
                type="password"
                placeholder="******************"
                autoComplete="current-password"
                disabled={loading}
                onChange={(event) => onChange(event)}
              />
              {Object.entries(error).length !== 0 &&
                error.constructor === Object &&
                error.message.map((err) => (
                  <div key={err.messages[0].id} style={{ marginBottom: 10 }}>
                    <p className="text-xs italic text-red-500">
                      {err.messages[0].message}
                    </p>
                  </div>
                ))}
            </div>
            <div className="flex items-center justify-between">
              <button
                className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700 focus:outline-none focus:ring"
                type="submit"
                disabled={loading}
              >
                {loading ? "Loading..." : "Sign In"}
              </button>
              <Link href="/forgot-password">
                <a className="inline-block align-baseline text-sm font-bold text-blue-500 hover:text-blue-800">
                  Forgot Password?
                </a>
              </Link>
            </div>
          </form>
        </div>
      </main>
    </Config>
  );
}
